exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-gutschein-5-f-6-jh-tsx": () => import("./../../../src/pages/gutschein-5f6jh.tsx" /* webpackChunkName: "component---src-pages-gutschein-5-f-6-jh-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-location-calden-tsx": () => import("./../../../src/pages/location/calden.tsx" /* webpackChunkName: "component---src-pages-location-calden-tsx" */),
  "component---src-pages-location-kassel-tsx": () => import("./../../../src/pages/location/kassel.tsx" /* webpackChunkName: "component---src-pages-location-kassel-tsx" */),
  "component---src-pages-location-vellmar-tsx": () => import("./../../../src/pages/location/vellmar.tsx" /* webpackChunkName: "component---src-pages-location-vellmar-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-fitnessinfo-fitness-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/fitnessinfo/fitness.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-fitnessinfo-fitness-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-fitnessinfo-health-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/fitnessinfo/health.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-fitnessinfo-health-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-fitnessinfo-lifestyle-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/fitnessinfo/lifestyle.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-fitnessinfo-lifestyle-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-coffee-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/offers/coffee.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-coffee-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-drink-flat-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/offers/drink_flat.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-drink-flat-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-egym-egym-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/offers/egym/egym.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-egym-egym-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-galileo-galileo-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/offers/galileo/galileo.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-galileo-galileo-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-matrix-matrix-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/offers/matrix/matrix.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-matrix-matrix-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-outdoor-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/offers/outdoor.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-outdoor-mdx" */),
  "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-precor-precor-mdx": () => import("./../../../src/templates/BasicMdxPage.tsx?__contentFilePath=/home/runner/work/1-a-fit.de/1-a-fit.de/content/offers/precor/precor.mdx" /* webpackChunkName: "component---src-templates-basic-mdx-page-tsx-content-file-path-content-offers-precor-precor-mdx" */)
}

